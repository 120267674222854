<template>
  <div class="list-user list-news admin_container_default">
    <div class="list-user-table p-0">
      <div class="mb-2 row p-0 admin_header">
        <div class="d-flex p-0 admin_title">
          <div class="text-truncate-mess header-font font-weight-bold">
            ニュース管理
          </div>
        </div>
        <div class="row-input admin_center"></div>
        <div class="tex-left text-md-right text-sm-right admin_right">
          <button
            v-on:click="gotoDashboard()"
            class="float-right button-back-dashboard"
          >
            戻る
          </button>
          <button
            v-on:click="createCategoryNew()"
            class="float-right button-create-user ml-2"
          >
            <!-- son -->
            ニュースカテゴリ管理
          </button>
          <button
            v-on:click="createNew()"
            class="float-right button-create-user"
          >
            ニュース作成
          </button>
        </div>
      </div>
      <div class="row px-3 py-3 d-flex">
        <div class="row-input m-0 cpx-4 w-50 my-2">
          <input
            class="form-control col-sm-12"
            v-model="infoNew.keywords"
            style="height: 35px"
            placeholder="検索"
            v-on:keyup="getListNews()"
          />
        </div>
      </div>
      <div class="row cpx-4">
        <div class="col-6 m-0 d-flex col-6 align-items-center px-2">
          <label class="col-3 font-weight-bold">投稿日時</label>
          <div class="col-4 p-0 fd-admin-listproperty-card-filter filter-date">
            <date-picker
              name="date"
              v-model="infoNew.fromTimePost"
              :config="optionsDate"
              autocomplete="off"
              placeholder="日付を選択"
              @input="getListNews()"
            ></date-picker>
          </div>
          <div class="col-1 p-0 text-center" style="font-size: 20px">~</div>
          <div class="col-4 p-0 fd-admin-listproperty-card-filter filter-date">
            <date-picker
              name="date"
              v-model="infoNew.toTimePost"
              :config="optionsDate"
              autocomplete="off"
              placeholder="日付を選択"
              @input="getListNews()"
            ></date-picker>
          </div>
        </div>
        <div class="col-6 m-0 d-flex col-6 align-items-center px-2">
          <label class="col-3 font-weight-bold">最終更新日時</label>
          <div class="col-4 p-0 fd-admin-listproperty-card-filter filter-date">
            <date-picker
              name="date"
              v-model="infoNew.fromTimeEdit"
              :config="optionsDate"
              autocomplete="off"
              placeholder="日付を選択"
              @input="getListNews()"
            ></date-picker>
          </div>
          <div class="col-1 text-center" style="font-size: 20px">~</div>
          <div class="col-4 p-0 fd-admin-listproperty-card-filter filter-date">
            <date-picker
              name="date"
              v-model="infoNew.toTimeEdit"
              :config="optionsDate"
              autocomplete="off"
              placeholder="日付を選択"
              @input="getListNews()"
            ></date-picker>
          </div>
        </div>
      </div>
      <Tables
        :items="listNew.data"
        :fields="fields"
        :itemsPerPageSelect="{}"
        class="cpx-4"
      >
        <template v-slot:page_content="{ item }">
          <td v-html="item.page_content"></td>
        </template>
        <template v-slot:id="{ index }">
          <td>
            {{ (listNew.current_page - 1) * listNew.per_page + index + 1 }}
          </td>
        </template>
        <template v-slot:post_date="{ item }">
          <td>
            {{
              new Date(item.post_date).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:updated_at="{ item }">
          <td>
            {{
              item.updated_at &&
              new Date(item.updated_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:action="{ item }">
          <td class="py-2 pl-2 pr-1 d-flex">
            <div class="w-15">
              <router-link
                :to="{
                  name: $route.params.shopId
                    ? 'edit new admin'
                    : 'edit new admin domain',
                  params: { shopId: $route.params.shopId, id: item.id },
                }"
                class="d-flex flex-column align-items-center"
              >
                <CButton
                  class="mx-1 btn-other"
                  color="primary"
                  square
                  variant="outline"
                  size="sm"
                  v-b-modal.modal-comfirm
                >
                  編集
                </CButton>
              </router-link>
            </div>
            <div>
              <CButton
                class="mx-1 btn-del"
                color="danger"
                square
                v-on:click="deleteNews(item.id)"
                variant="outline"
                size="sm"
              >
                削除
              </CButton>
            </div>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listNew.total"
      >
        <p class="font-weight-bold">
          {{ listNew.total }}件中{{ listNew.data.length }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listNew.last_page"
      >
        <CPagination
          v-if="listNew.total"
          :activePage.sync="page"
          :pages="listNew.last_page"
          align="center"
        />
        <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div>
      </div>
    </div>
    <div>
      <b-modal id="delete-modal-new" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>
            削除後、ご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
          </h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            v-on:click.prevent="
              deleteNew({ id: infoNew.id, shop_id: shop_id, screen: 'admin' })
            "
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-new')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { Constants } from "../../utils/constants";
import { mapActions, mapGetters } from "vuex";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});
export default {
  name: "ListNew",
  data() {
    return {
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      infoNew: {
        id: "",
        keywords: "",
        category: "",
        fromTimePost: "",
        toTimePost: "",
        fromTimeEdit: "",
        toTimeEdit: "",
      },
      fields: tableFields.NEWS,
      listLimitRecordPage: [
        { value: 10, text: "10" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      limit: 10,
      page: 1,
      optionsDate: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
      optionCategory: [],
    };
  },
  components: {
    Tables,
  },
  created() {
    this.getListNews(1);
    const request = {
      shop_id: this.shop_id,
      screen: "admin",
    };
    this.getListCategoryNew(request);
  },
  computed: {
    ...mapGetters([
      "listNew",
      "message",
      "success",
      "error",
      "listCategoryNew",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.$bvModal.hide("delete-modal-new");
        this.getListNews(this.page);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listNew() {
      this.page = this.listNew.current_page;
    },
    page() {
      this.getListNews(this.page);
    },
    listCategoryNew() {
      this.optionCategory = this.listCategoryNew.map((item) => ({
        value: item.title,
        text: item.title,
      }));
      this.optionCategory.unshift({
        value: "",
        text: "カテゴリを選んでください",
      });
    },
  },
  methods: {
    ...mapActions({
      getListNew: "getListNew",
      deleteNew: "deleteNew",
      getListCategoryNew: "getListCategoryNew",
    }),
    createNew() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "create new admin"
          : "create new admin domain",
      });
    },
    getListNews(page) {
      if (
        (this.infoNew.fromTimePost &&
          this.infoNew.toTimePost &&
          this.infoNew.fromTimePost > this.infoNew.toTimePost) ||
        (this.infoNew.fromTimeEdit &&
          this.infoNew.toTimeEdit &&
          this.infoNew.fromTimeEdit > this.infoNew.toTimeEdit)
      ) {
        this.$toasted.error("該当日時の検索はできません。");
      } else {
        if (page === undefined) {
          page = this.page;
        }
        const formData = {
          screen: "admin",
          page: page,
          data: {
            shop_id: this.shop_id,
            keywords: this.infoNew.keywords,
            type: this.infoNew.category,
            post_start_date: this.infoNew.fromTimePost,
            post_end_date: this.infoNew.toTimePost,
            update_start_date: this.infoNew.fromTimeEdit,
            update_end_date: this.infoNew.toTimeEdit,
            limit: this.limit,
          },
        };
        this.getListNew(formData);
      }
    },
    changeLimitPage() {
      this.getListNews(1);
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Dashboard Admin" });
      } else {
        this.$router.push({ name: "Dashboard Admin domain" });
      }
    },
    resetFieldSearch() {
      this.infoNew.keywords = "";
      this.infoNew.category = "";
      this.infoNew.fromTimePost = "";
      this.infoNew.toTimePost = "";
      this.infoNew.fromTimeEdit = "";
      this.infoNew.toTimeEdit = "";
      const formData = {
        screen: "admin",
        page: 1,
        data: {
          shop_id: this.shop_id,
          keywords: this.infoNew.keywords,
          type: this.infoNew.category,
          post_start_date: this.infoNew.fromTimePost,
          post_end_date: this.infoNew.toTimePost,
          update_start_date: this.infoNew.fromTimeEdit,
          update_end_date: this.infoNew.toTimeEdit,
          limit: this.limit,
        },
      };
      this.getListNew(formData);
    },
    deleteNews(id) {
      this.infoNew.id = id;
      this.$bvModal.show("delete-modal-new");
    },
    createCategoryNew() {
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "list category new",
          params: { shopId: this.shop_id },
        });
      } else {
        this.$router.push({
          name: "list category new domain",
        });
      }
    },
  },
};
</script>
